:root {
    --containerWidth: 1328px;
    --colorRed: #db3244;
}

* {
    box-sizing: border-box;
    /* font-family: Segoe UI, Helvetica, Arial, sans-serif; */
    font-family: Helvetica, Arial, sans-serif;
    text-decoration: none;
    scroll-margin-top: 64px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

html {
    scroll-behavior: smooth;
}

html,
body {
    /* height: 100%; */
    min-height: 100vh;
    overflow-y: hidden auto;
    /* overflow: hidden; */
    letter-spacing: 0;
    scroll-behavior: smooth;
}

a {
    color: inherit;
}

a:hover {
    text-decoration: none;
}

.App {
    width: 100%;
    height: 100%;
    display: flex;
    /* overflow: hidden; */
    flex-direction: column;
}

body {
    /* background: #f4f6f8; */
    overflow-y: overlay
}

.google-chart svg>g>g:last-child {
    pointer-events: none
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

body.hidden,
body.hidden .autoHiden {
    overflow: hidden !important;
    overflow-y: hidden !important;
}

.custom_scroll {
    overflow-y: overlay;
}

body.is-iframe::-webkit-scrollbar,
.custom_scroll::-webkit-scrollbar {
    width: 7px;
    -webkit-appearance: none;
}

/* Track */
body.is-iframe::-webkit-scrollbar-track,
.custom_scroll::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, .7);
    border-radius: 15px;
}

/* Handle */
body.is-iframe::-webkit-scrollbar-thumb,
.custom_scroll::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 15px;
}

body.is-iframe::-webkit-scrollbar-track,
.custom_scroll::-webkit-scrollbar-track {
    background: transparent;
}

body.is-iframe::-webkit-scrollbar-thumb,
.custom.custom_scroll::-webkit-scrollbar-thumb {
    background: transparent;
}

body.is-iframe::-webkit-scrollbar-thumb,
.custom.custom_scroll:hover::-webkit-scrollbar-thumb {
    /* background: rgb(210, 210, 210); */
    background: #888;

}

.custom_scroll::-webkit-scrollbar:horizontal {
    height: 8px;
    border-radius: 15px;
}

.custom_scroll::-webkit-scrollbar-thumb:horizontal {
    height: 5px;
    border-radius: 15px;
}

/* Handle on hover */
/* body.is-iframe::-webkit-scrollbar-thumb:hover,
.custom_scroll::-webkit-scrollbar-thumb:hover,
.custom.custom_scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
} */

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

#credential_picker_container {
    top: 57px !important;
    right: 9px !important;
    z-index: 1034 !important;
}

.g-one-tap-w {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 80px;
    height: 80px;
    cursor: pointer;
}

.g-one-tap-c {
    transition: transform 0.05s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    background-color: rgb(32, 33, 36);
    position: absolute;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    transform: scale(0);
    right: 12px;
    top: 19px;
    opacity: 0.04;
}

#video-popper,
.video-popper {
    z-index: 1030;
}

.tox-dialog__disable-scroll .MuiModal-root {
    display: none;
}

.notCursor {
    cursor: not-allowed;
}

.code-highlight {
    box-shadow: inset 0 -1.2rem var(--color), inset 0 0 var(--color);
    font-style: normal;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes animateShow {
    0% {
        opacity: 0 !important;
    }

    100% {
        opacity: 1 !important;
    }
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}
@keyframes ping {
    75%,to {
        transform: scale(2.5);
        opacity: 0
    }
}